<template>
	<div>
		<b-overlay :show="isLoading">
			<span v-if="curSeason === false">unexistent cur season</span>
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Nombre</b-th>
						<b-th>Dias</b-th>
						<b-th>Creado por</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td>{{ row.name }}</b-td>
						<b-td>
							<div class="d-flex flex-column align-items-center" style="gap: 0.5rem">
								<b-badge v-for="day in row.days" :key="day">{{ day }}</b-badge>
							</div>
						</b-td>
						<b-td>
							{{ row.created_by.user }}
							<br />
							<small>{{ row.created_by.date | myGlobalDayShort }}</small>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center" style="gap: 0.5rem">
								<b-button size="sm" variant="flat-danger" @click="remove(row)">
									<feather-icon icon="TrashIcon" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="4">
							<p class="mb-0">No hay registros por mostrar</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<b-modal
			centered
			title="Registrar nuevo bloque"
			size="lg"
			v-model="creationModal.active"
			@show="handleShow"
			@hidden="handleHidden"
		>
			<b-overlay :show="creationModal.loading">
				<b-form-group label="Nombre">
					<b-form-input v-model="creationModal.name" />
				</b-form-group>
				<b-form-group label="Dias">
					<b-form-checkbox-group
						v-model="creationModal.days"
						:options="daysOpts"
						buttons
						button-variant="outline-primary"
					/>
				</b-form-group>
			</b-overlay>
			<template #modal-footer>
				<b-button
					variant="success"
					@click="create"
					:disabled="!!!creationModal.name || creationModal.days.length == 0"
					>Guardar</b-button
				>
			</template>
		</b-modal>
	</div>
</template>

<script>
import preparacionService from "@/services/preparacion/settings.service";

export default {
	props: {
		action: String,
	},
	data: () => ({
		isLoading: false,
		rows: [],
		creationModal: {
			active: false,
			id: null,
			name: "",
			days: [],
			loading: false,
		},
		daysOpts: [
			{ text: "Lunes", value: 1, disabled: false },
			{ text: "Martes", value: 2, disabled: false },
			{ text: "Miercoles", value: 3, disabled: false },
			{ text: "Jueves", value: 4, disabled: false },
			{ text: "Viernes", value: 5, disabled: false },
			{ text: "Sabado", value: 6, disabled: false },
			{ text: "Domingo", value: 7, disabled: false },
		],
		curSeason: null,
	}),
	methods: {
		handleHidden() {
			this.creationModal = { active: false, id: null, name: "", status: null, loading: false, editing: false };
			this.daysOpts = [
				{ text: "Lunes", value: 1, disabled: false },
				{ text: "Martes", value: 2, disabled: false },
				{ text: "Miercoles", value: 3, disabled: false },
				{ text: "Jueves", value: 4, disabled: false },
				{ text: "Viernes", value: 5, disabled: false },
				{ text: "Sabado", value: 6, disabled: false },
				{ text: "Domingo", value: 7, disabled: false },
			];
			this.$emit("actionDone");
		},
		async getRows() {
			this.isLoading = true;
			const { data } = await preparacionService.getBlocks({ season_id: this.curSeason });
			this.rows = data;
			this.isLoading = false;
		},
		async getSeasons() {
			this.isLoading = true;
			const { data } = await preparacionService.getSeasons();
			let active = data.find((season) => season.active_season == 1);
			if (!active) this.curSeason = false;
			else this.curSeason = active.id;
			this.isLoading = false;
			if (typeof this.curSeason == "number") this.getRows();
		},
		async handleShow() {
			this.creationModal.loading = true;
			const { data } = await preparacionService.getAvailableDays({ season_id: this.curSeason });
			data.forEach((day) => {
				if (day.busy_day) {
					let idxMatch = this.daysOpts.findIndex((opt) => opt.value == day.id);
					if (idxMatch == -1) return;
					this.daysOpts[idxMatch].disabled = true;
				}
			});
			this.creationModal.loading = false;
		},
		async create() {
			this.creationModal.loading = true;
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se registrara el bloque ${this.creationModal.name}`,
			});
			if (!isConfirmed) {
				this.handleHidden();
				return;
			}
			const { status, message } = await preparacionService.createBlock({
				name: this.creationModal.name,
				days: this.creationModal.days,
				season_id: this.curSeason,
			});
			if (status) {
				this.handleHidden();
				this.getRows();
				this.showToast("success", "top-right", "Bloques", "SuccessIcon", message);
				return;
			}
			this.creationModal.loading = false;
		},
		async remove(row) {
			this.isLoading = true;
			const { isConfirmed } = await this.showConfirmSwal({ text: `Se eliminara el bloque ${row.name}` });
			if (!isConfirmed) {
				this.isLoading = false;
				return;
			}
			await preparacionService.deleteBlock({ id: row.training_block_id });
			this.isLoading = false;
			this.getRows();
		},
	},
	created() {
		this.getSeasons();
	},
	watch: {
		action(val) {
			if (val == "create-block") {
				this.creationModal.active = !this.creationModal.active;
			}
		},
	},
};
</script>
